.react-checkbox-tree {
  color: #444;
}

.clickable-labels {
  display: flex;

  >* {
    width: 50%;
  }
}

.expand-all-container {
  max-width: 400px;
}

.rct-node-icon .far {
  width: 1em;
  text-align: center;
}

.filter-container>.filter-text {
  display: block;
  margin-bottom: .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  background-clip: padding-box;
  padding: .375rem .75rem;
  line-height: 1.5;
  font-size: 1rem;
}

.react-checkbox-tree {
  text-align: left;
}

.react-checkbox-tree ol {
  list-style: none;
}

.react-checkbox-tree .bar-tree {
  width: 100%;
  padding: 4px 10px;
  border-top: 1px solid #ced4da;
  min-height: 22px;
}

.react-checkbox-tree .bar-header-tree {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
}

.react-checkbox-tree .bar-header-tree div:nth-child(2) {
  display: flex !important;
  align-items: center;
}

.react-checkbox-tree .bar-detail-tree {
  padding: 8px;
}

.rct-text {
  display: flex;
  position: relative;
  height: 100%;
}

.react-label-tree {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: auto;
  margin-top: auto;
  display: flex;
}

.rct-node-parent {
  margin-bottom: .5rem;
  padding: 5px !important;
  border-top: 1px solid #ced4da;
}

.rct-node-parent>.bar-tree {
  border: 0 !important;
  border-radius: 4px;
  width: auto !important;
  padding: 5px !important;
  background-color: #fff;
}

.rct-node-parent>.bar-tree .rct-text .rct-title {
  font-weight: bold;
}

.rct-node-parent>ol>.rct-node-parent>.bar-tree .rct-text .rct-title {
  font-weight: normal;
}

.rct-node-parent>ol>.rct-node-parent {
  margin-bottom: 0 !important;
}

.rct-node-parent>ol>.rct-node-parent>.bar-tree {
  background-color: #fff;
}

.rct-node-parent>ol>.rct-node-leaf>.bar-tree {
  width: auto !important;
}