.panel .panel-body {
  padding: 14px;
  transition: height 0.3s ease-out;
}

.mail-messages>div>.mail-composer:first-child {
  margin: 0 0 20px 0;
}

.mail-thread {
  margin-left: 20px;
  border-left: 2px solid #ddd;
}

.form-mail .fadeIn {
  opacity: 1;
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.mail-message-main {
  display: flex;
}

.mail-thread .avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: -21px;
  border-radius: 500px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, .15);
}

.bg-blue {
  background-color: #2196f3;
}

.mail-thread .avatar {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 40px;
  margin-left: -12px;
  border-radius: 500px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, .15);
}

.mail-thread .mail-message {
  position: relative;
  margin-left: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  width: 100%;
}


.mail-thread .mail-message>.arrow {
  position: absolute;
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid #ddd;
  left: -7px;
  top: 12px;
  background-color: #fff;
  border-right: 0;
  border-bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mail-message .mail-message-header {
  font-weight: 600;
  padding: 10px 16px;
  border-bottom: 1px solid #ddd;
}

.mail-thread .mail-message-body {
  padding: 8px 16px;
  padding-top: 12px;
}

.mail-message-body {
  word-break: break-all;
}

.mail-thread .mail-message-body img {
  max-width: 100%;
  max-height: 500px;
}

.mail-thread .mail-message-footer {
  font-size: 12px;
  margin-top: 6px;
  color: rgba(1, 1, 1, 0.7);
}

.mail-thread .mail-message-footer a {
  margin-right: 4px;
  margin-left: 4px;

}

.small-comment {
  height: 40px;
  font-size: 14px;
  padding-left: 2rem;
}

.middle-comment {
  height: 40px;
  font-size: 12px;
  padding-left: 2rem;
}

.mail-thread-nodata {
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-checkbox-tree {
  margin: 0;
}

.react-checkbox-tree ol {
  list-style: none;
  padding-inline-start: 30px;
}

.react-checkbox-tree>ol {
  padding: 0;
}

.bt-picture {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  opacity: 0.5;
}

.bt-picture:hover {
  opacity: 1;
  transition: all 0.2;
}

.bt-sent {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  opacity: 0.5;
}

.bt-sent:hover {
  opacity: 1;
  transition: all 0.2;
}

.ant-input-affix-wrapper-disabled .bt-sent:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 800px) {
  .mail-thread .mail-message-footer {
    font-size: 8px;
  }

  .react-checkbox-tree {
    margin-right: 30px;
    margin-left: 0px;
  }

  .react-checkbox-tree ol {
    list-style: none;
    padding-inline-start: 20px;
  }

  .mail-thread {
    margin-top: 0px;
    margin-left: 2px;
    border-left: 1px solid #ddd;
  }

  .mail-thread .mail-message {
    margin-left: 8px;
  }
}