@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

* {
  font-family: "IBM Plex Sans" !important;
}

.card-li {
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.card-li:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.35);
  opacity: 0.8;
}

.card-main {
  border: 1px solid #FCFCFC;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 32px 24px 64px 24px;
}

.site-layout {
  margin-left: 250px;
}

.img-logo {
  width: 100px;
  height: 100px;
}

.header-ui .mobild-ui {
  display: none;
}

.table-list>.ant-card-body {
  padding: 0 !important;
}

@media only screen and (max-width: 900px) {
  .bt-main {
    width: 100% !important;
  }

  .header-ui .mobild-ui {
    display: flex;
    height: 100%;
  }

  .header-ui {
    background-color: #000000 !important;
    margin-bottom: auto;
    margin-top: auto;
  }

  .bamberger-ui {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  .ant-drawer-header {
    background-color: #000000 !important;
  }

  .ant-drawer-body {
    background-color: #000000 !important;
  }
}

.card-drop-here {
  width: 100%;
  height: 190px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px;
  text-align: center;
}

.card-drop-here:hover,
.card-drop-here:active {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.card-drop-here.disabled {
  opacity: 0.6;
}


.ant-modal-confirm-error .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-error .ant-modal-confirm-btns {
  text-align: center;
}


.ant-modal-confirm-success .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
}

.ant-modal-confirm-success .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-btns {
  text-align: center;
}


.ant-modal-confirm-warning .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-btns {
  text-align: center;
}

.ant-modal-confirm-success .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #000 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-error .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #000 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-warning .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #000 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-title {
  font-size: 18px !important;
}

.ant-modal-confirm-content {
  font-size: 16px !important;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary {
  margin: 0;
  background-color: #000;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary:active {
  background-color: #000;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default {
  background-color: #000;
  color: #fff;
  border-color: #000;

}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default:hover,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default:active {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

.ant-btn-danger {
  background-color: #FF0000 !important;
  /* border-radius: 20px; */
  /* height: 40px; */
  min-width: 60px;
  color: #fff;
}

.main-container {
  display: flex;
}


.public-form {
  padding: 64px 8px;
  margin: 0px auto;
  width: 50%;
  text-align: start;
}

.logo-container {
  text-align: center;
  margin-bottom: 25px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.list-container {
  display: flex;
  width: 100%;
  /* background-color:#ededed; */
}

.card-container {
  width: 100%;
  padding: 0px 0px;
}

.card {
  background-color: white;
  padding: 10px 20px;
  margin: 10px 0;
  position: relative;
  transition: all 0.2s;
}

.card.custom{
  padding: 10px 20px 10px 10px;
}

.card::after {
  content: "";
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 20px;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 5px;
}

.circle span{
  height: 100%;
  display: flex;
  align-items: center;
}

.serial-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-right: 10px;
  min-width: 110px;
}

.serial-container span:nth-child(1) {
  font-size: 10px;
  font-weight: 500;
}

.serial-container span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

.serial-container span:nth-child(3){
  font-weight: bold;
}

.divider {
  width: 1.5px;
  background: rgba(5, 5, 28, 0.22);
  height: 45px;
}

.regis-container{
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
  margin-top:10px;
}

.regis-container span{
  font-size: 14px;
  font-weight: bold;
}

.status-container {
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
}

.card.planned {
  background: white;
}

.card.planned::after,
.card.planned .circle {
  background-color: #B9B9B9;
}

.card.in-progress {
  background: #FFF1925E;
}

.card.in-progress::after,
.card.in-progress .circle {
  background: #FFDD3C;
}

.card.contact-helpdesk, .card.unit-replacement {
  background: #F5DCE0;
}

.card.contact-helpdesk::after,
.card.contact-helpdesk .circle,
.card.unit-replacement::after,
.card.unit-replacement .circle{
  background: #FF0000;
}

.card.checklist-done {
  background: #E5EFF6;
}

.card.checklist-done::after,
.card.checklist-done .circle {
  background: #116BEA;
}

.card.installation-completed {
  background: #e6f6e5;
}

.card.installation-completed::after,
.card.installation-completed .circle {
  background: #8fd14f;
}

.detail-container {
  display: flex;
  width: 100%;
}

.planned-container {
  line-height: 2em;
}

.bold {
  font-weight: bold;
}

.indent {
  margin-left: 30px;
}

.product-name {
  font-size: 24px;
  font-weight: 700;
  margin: 5px 0;
}

.product-detail {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.nn-steps-current {
  text-align: left;
  color: var(--primary-orange, #F28626);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.nn-steps-view {
  padding-top: 4px;
  padding-bottom: 8px;
}

.nn-space-between-xl {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.ant-steps-item-finish .ant-steps-item-title::after {
  background-color: #F28626 !important;
  height: 2px !important;
}

.ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #C8C8CD !important;
}

.ant-steps-icon {
  font-weight: 600;
  color: #C8C8CD !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #F28626 !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-finish .ant-steps-icon {
  font-weight: 600;
  color: #fff !important;
}


.ant-steps-item-active .ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-active .ant-steps-icon {
  font-weight: 600;
  color: #F28626 !important;
}

.ant-form-item {
  margin: 0;
}

.ant-form-item-row {
  align-items: flex-start;
}

.ant-form-item-label {
  margin-top: .75rem;
}

.ant-form-item-label-left {
  min-width: 200px !important;
}

.ant-form-item-label>label {
  font-size: 16px !important;
}

.step-container {
  margin: 20px 0;
  padding: 0px 8px;
}

.next-container {
  width: 50%;
  margin: 20px auto;
}

.next-container button {
  background: #3D3D3D;
}

.main-comment {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 8px;
}

.header-comment {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.btn-group-stop {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.ant-menu-item {
  padding: 0 10px 0 15px !important;
}

.ant-menu-title-content {
  margin-inline-start: 10px !important;
  white-space: initial !important;
  line-height: 20px !important;
}

.ant-radio-wrapper {
  gap: .5rem;
}

.spin-loading{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00000030;
  z-index: 10000;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ant-spin-dot-item{
  background-color: #fff !important;
}

.phone-box{
  display: flex;
  gap:5px;
}

.phone-box .ant-select-selector{
  padding: 0 0 0 15px !important;
}

.phone-box .ant-form-item:nth-child(1){
  width: 170px;
}

.phone-box .ant-form-item:nth-child(2){
  width: 100%;
}

.megaforce-form .ant-form-item-required::before{
  display: none !important;
}

.ant-picker-disabled{
  background: rgb(238, 238, 238) !important;
}

.ant-picker-disabled input{
  color: black !important;
}

.done-btn{
  background-color: #0275D8 !important;
}

.done-btn:not(:disabled):hover{
  background-color: #1a83dd !important;
}

.done-btn:disabled, .replace-btn:disabled{
  background-color: #B9B9B9 !important;
  color:#ffffff;
  cursor: not-allowed;
}

.ant-btn-danger:hover{
  background-color: #ff3131 !important;
}

.replace-btn{
  background-color: #ff3131 !important;
}

.replace-btn:disabled:hover{
  background-color: #B9B9B9 !important;
  color:#ffffff !important;
}

.rg-customHeader-cell{
  background: #8080801a;
}

.d-flex{
  display: flex;
}

.d-align-center{
  align-items: center;
}

.d-justify-center{
  justify-items: center;
}

.custom-label .ant-form-item-row {
  display: flex;
  align-items: center;
}

.custom-label .ant-form-item-label{
  margin: 0;
  padding: 0;
}

.invest-issue{
  margin-bottom: 20px;
}

.invest-issue .invest-label{
  margin-bottom: 5px;
  font-weight: bold;
}

.invest-issue .invest-label label span{
  color: red;
}

.custom-select{
  width: 100%;
  padding: 4.5px 0px;
  font-size: 16px
}

.custom-select .ant-select-selector{
  font-size: 16px !important;
}

@media only screen and (max-width: 900px) {
  .public-form {
    padding: 40px 8px;
    width: 84%;
  }

  /* .circle {
    height: 20px;
  } */


  .site-layout {
    margin-left: 0px;
  }

  .app-ui-left {
    width: 100%;
  }

  .app-ui-right {
    display: none;
  }

  .card-main {
    padding: 22px 12px;
  }

  .card-container {
    padding: 0px 0px !important;
  }

  .serial-container {
    min-width: 70px;
  }

  .btn-group-stop {
    flex-direction: column;
  }
}

.header-container {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 26px;
}

.subheader-container {
  color: #737C89;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

#reader video {
  width: 100% !important;
}

.ant-table-cell {
  padding: .5rem !important;
}

.pdf-preview-container{
  height:100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

@media only screen and (max-width: 532px) {
  .phone-box{
    display: block;
  }
  
  .phone-box .ant-form-item:nth-child(1){
    width: 100%;
  }
  
  .phone-box .ant-form-item:nth-child(2){
    width: 100%;
  }

  .serial-container span:nth-child(3), .status-container span {
    font-size: 12px !important;
  }

  .regis-container span {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 450px) {
  .public-form {
    padding: 40px 8px;
    width: 90%;
  }

  .card-container,
  .card {
    padding: 15px;
  }

  .ant-picker-dropdown {
    left: 0 !important;
  }

  .ant-picker-dropdown .ant-picker-datetime-panel {
    flex-direction: column;
  }

}